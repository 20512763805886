import {
  convertEmptyValueOrMinusCharToZero,
  convertUndefinedValueToZero,
  isEmptyValue,
  roundPrice,
  roundToInteger
} from "../helpers/methods";

export default function useOfferTableService() {

  const calculateElementQuotation = (price, quantity) => {
    return roundPrice(convertUndefinedValueToZero(price) * convertUndefinedValueToZero(quantity));
  };

  const calculateMarginPln = (margin_proc, price) => {
    return roundPrice(convertUndefinedValueToZero(margin_proc)/ 100 * convertUndefinedValueToZero(price))
  };

  const calculatePriceWithMargin = (margin_pln, price) => {
    return parseFloat(convertUndefinedValueToZero(price)) + parseFloat(convertUndefinedValueToZero(margin_pln))
  }

  const calculateQuotationMarginPln = (margin_unit, quantity) => {
    return roundPrice(convertUndefinedValueToZero(margin_unit)* convertUndefinedValueToZero(quantity))
  };

  const calculateDiscountPln = (discount_proc, price_with_margin) => {
    return roundPrice(convertUndefinedValueToZero(discount_proc) / 100 * convertUndefinedValueToZero(price_with_margin))
  };

  const calculateQuotationDiscountPln = (discount_unit, quantity) => {
    return roundPrice(convertUndefinedValueToZero(discount_unit)* convertUndefinedValueToZero(quantity))
  };

  const calculateMarginProcOnMarginPlnInput = (price, margin_unit) => {
    if (price > 0) {
      return convertUndefinedValueToZero(margin_unit / price * 100);
    }
    return 0
  };

  const calculateDiscountProcOnDiscountPlnInput = (price_with_margin, discount_unit) => {
    if (price_with_margin > 0) {
      return convertUndefinedValueToZero(discount_unit / price_with_margin * 100);
    }
    return 0
  };

  const calculateItemDiscountProc = (items_cost, items_discount_pln) => {
    if (items_cost > 0) {
      return convertUndefinedValueToZero(roundPrice(items_discount_pln / items_cost * 100));
    }
    return 0
  };


  const calculateItemMarginProc= (items_cost, items_margin_pln) => {
    if (items_cost > 0) {
      return convertUndefinedValueToZero(roundPrice(items_margin_pln / items_cost * 100));
    }
    return 0
  };

  const calculateElementProfit = (discount_pln, margin_pln) => {
    return roundPrice(convertUndefinedValueToZero(margin_pln) - convertUndefinedValueToZero(discount_pln))
  };

  const calculateElementQuotationNet = (price_total, profit) => {
    return roundPrice(convertUndefinedValueToZero(price_total) + convertUndefinedValueToZero(profit))
  };

  const calculateElementQuotationVat = (quatation_net, vat_proc) => {
    return roundPrice(convertUndefinedValueToZero(vat_proc)/ 100 * convertUndefinedValueToZero(quatation_net))
  };

  const calculateElementQuotationGross = (quatation_net, quatation_vat) => {
    return roundPrice(convertUndefinedValueToZero(quatation_net) + convertUndefinedValueToZero(quatation_vat))
  };

  const calculateElement = (
    element,
    prefix,
    requiredFields,
    calulate_margin_pln = false, 
    calulate_discount_pln = false,
    calculateFields
  ) => {
    if (calculateFields === undefined) {
      calculateFields = [
        `${prefix}_quotation`,
        `${prefix}_margin_pln`,
        `${prefix}_price_with_margin`,
        `${prefix}_quotation_margin`,
        `${prefix}_discount_pln`,
        `${prefix}_quotation_discount`,
        `${prefix}_profit`,
        `${prefix}_quotation_net`,
        `${prefix}_quotation_vat`,
        `${prefix}_quotation_gross`,
      ];
    }
    if (requiredFields === undefined) {
      requiredFields = []; 
    }


    for (var field of calculateFields) {
      var value = element[field];
      if (field === `${prefix}_quotation`) {
        value = calculateElementQuotation(element[`${prefix}_price`], element[`${prefix}_quantity`])
      }
      else if (field === `${prefix}_margin_pln` && calulate_margin_pln) {
        value = calculateMarginPln(element[`${prefix}_margin_proc`], element[`${prefix}_price`])
      }
      else if (field === `${prefix}_price_with_margin`) {
        value = calculatePriceWithMargin(element[`${prefix}_margin_pln`], element[`${prefix}_price`])
      }
      else if (field === `${prefix}_quotation_margin`) {
        value = calculateQuotationMarginPln(element[`${prefix}_margin_pln`], element[`${prefix}_quantity`])
      }
      else if (field === `${prefix}_discount_pln` && calulate_discount_pln) {
        value = calculateDiscountPln(element[`${prefix}_discount_proc`], element[`${prefix}_price_with_margin`])
      }
      else if (field === `${prefix}_quotation_discount`) {
        value = calculateQuotationDiscountPln(element[`${prefix}_discount_pln`], element[`${prefix}_quantity`])
      }
      else if (field === `${prefix}_profit`) {
        value = calculateElementProfit(element[`${prefix}_quotation_discount`], element[`${prefix}_quotation_margin`]);
      }
      else if (field === `${prefix}_quotation_net`) {
        value = calculateElementQuotationNet(element[`${prefix}_quotation`], element[`${prefix}_profit`]);
      }
      else if (field === `${prefix}_quotation_vat`) {
        value = calculateElementQuotationVat(element[`${prefix}_quotation_net`], element[`${prefix}_vat_proc`]);
      }
      else if (field === `${prefix}_quotation_gross`) {
        value = calculateElementQuotationGross(element[`${prefix}_quotation_net`], element[`${prefix}_quotation_vat`]);
      }
      element[field] = value;
    }
    isElementValid(element, requiredFields)
    return element;
  };

  const prepareInitSummaryElementsDict = (summaryFields) => {
    return summaryFields.reduce((summary, field) => {
      summary[field] = 0;
      return summary;
    }, {});
  }

  const summaryElementsFields = (elements, prefix, summaryFields) => {
    if (summaryFields === undefined) {
      summaryFields = [
        `${prefix}_quotation`,
        `${prefix}_quotation_margin`,
        `${prefix}_quotation_discount`,
        `${prefix}_quotation_net`,
        `${prefix}_quotation_vat`,
        `${prefix}_quotation_gross`,
      ];
    }
    var summaryElementsDict = prepareInitSummaryElementsDict(summaryFields)
    elements.forEach((obj) => {
      summaryFields.forEach((field) => {
        if (obj.isDeleted !== true) {
          var valueToAdd = parseFloat(convertEmptyValueOrMinusCharToZero(obj[field]));
          summaryElementsDict[field] += valueToAdd;
        }
      });
    });
    return summaryElementsDict;
  };

  const calculateItemsTotalCost = (servicesSummary, partsSummary) =>{
    let totalCostData = {}
    totalCostData.items_quotation = servicesSummary.seof_quotation + partsSummary.paof_quotation
    totalCostData.items_quotation_margin = servicesSummary.seof_quotation_margin + partsSummary.paof_quotation_margin
    totalCostData.items_margin_proc = calculateItemMarginProc(totalCostData.items_quotation, totalCostData.items_quotation_margin)
    totalCostData.items_quotation_discount = servicesSummary.seof_quotation_discount+ partsSummary.paof_quotation_discount
    totalCostData.items_quotation_with_margin = totalCostData.items_quotation + totalCostData.items_quotation_margin 
    totalCostData.items_discount_proc = calculateItemDiscountProc(totalCostData.items_quotation_with_margin , totalCostData.items_quotation_discount)
    totalCostData.items_quotation_net = servicesSummary.seof_quotation_net + partsSummary.paof_quotation_net
    totalCostData.items_quotation_vat = servicesSummary.seof_quotation_vat + partsSummary.paof_quotation_vat
    totalCostData.items_quotation_gross = servicesSummary.seof_quotation_gross + partsSummary.paof_quotation_gross
    totalCostData.editDate = Date.now()
    return [totalCostData];
  }
  const getNumberOfNotDeletedRows = (data) => {
    return data.filter((row) => row.isDeleted !== true).length
  }

  const setServiceOfferDataAfterChange = (element, service) => {
    element.item_identifactor = service.item__item_number ? `${service.item__item_number} ${service.item__item_name}` : ""
    element.seof_name = service.service_name
    element.service = service.id
    element.seof_suplier = service.service_suplier
    element.gtu = service.gtu__gtu_name
    element.service_unit = service.service_unit
    element.seof_price = service.service_price
    element.active = service.service_active
    element.seof_margin_pln = service.service_margin_min_pln
    element.paof_price_with_margin = calculatePriceWithMargin(element.seof_margin_pln, element.seof_price)
    element.seof_discount_pln = 0
    element.account = service.account
    element.seof_vat_proc = service.service_vat_proc
    element.seof_margin_proc = calculateMarginProcOnMarginPlnInput(element.seof_price, element.seof_margin_pln)
    element.seof_discount_proc = calculateDiscountProcOnDiscountPlnInput(element.seof_price, element.seof_discount_pln)
    element.seof_quotation_margin = calculateMarginPln(element.seof_margin_pln, element.seof_quantity )
    return element
  }

  const setPartOfferDataAfterChange = (element, part) => {
    element.item_identifactor = part.item__item_number ? `${part.item__item_number} ${part.item__item_name}` : ""
    element.paof_name = part.part_name
    element.part = part.id
    element.part_suplier = part.part_suplier__subcontractor_short_name
    element.gtu = part.gtu__gtu_name
    element.part_unit = part.part_unit
    element.paof_price = part.part_price
    element.active = part.part_active
    element.paof_margin_pln = part.part_margin_min_pln
    element.paof_price_with_margin = calculatePriceWithMargin(element.paof_margin_pln, element.paof_price)
    element.paof_discount_pln = 0
    element.account = part.account
    element.paof_vat_proc = part.part_vat_proc
    element.paof_margin_proc = calculateMarginProcOnMarginPlnInput(element.paof_price, element.paof_margin_pln)
    element.paof_discount_proc = calculateDiscountProcOnDiscountPlnInput(element.paof_price, element.paof_discount_pln)
    element.paof_quotation_margin = calculateMarginPln(element.paof_margin_pln, element.paof_quantity )
    return element
  }

  const setInititalServiceData = (offerServices, services) => {
    for (let offerService of offerServices) {
      let service = services.find((serv) => offerService.service === serv.id)
      offerService.service_suplier = service.service_suplier__subcontractor_short_name
      offerService.gtu = service.gtu__gtu_name
      offerService.service_unit = service.service_unit
    }
    return offerServices
  }

  const setInititalPartData = (offerParts, parts) => {
    for (let offerPart of offerParts) {
      let part = parts.find((p) => offerPart.part === p.id)
      offerPart.part_suplier = part.part_suplier__subcontractor_short_name
      offerPart.gtu = part.gtu__gtu_name
      offerPart.part_unit = part.part_unit
    }
    return offerParts
  }

  const isElementValid = (element, requiredFields) => {
    element.isValid = requiredFields.every(
      (fieldName) => !isEmptyValue(element[fieldName])
    ) 
  };

  const isTableValid = (elements) => {
      return elements
        .filter(
          (element) => element.isDeleted !== true
        )
        .every(
          (element) => element.isValid !== false && element.active !== false
        )
  }

  const getFieldsNameToSave = (prefix) => {
    return [
      "id",
      `${prefix}_name`,
      prefix === 'seof' ? "service" : 'part',
      `account`,
      `${prefix}_quantity`,
      `${prefix}_price`,
      `${prefix}_margin_pln`,
      `${prefix}_margin_proc`,
      `${prefix}_discount_pln`,
      `${prefix}_discount_proc`,
      `${prefix}_note`,
      `${prefix}_vat_proc`,
      `${prefix}_suplier`,
      'isDeleted'
    ];
  };

  

  const getOfferFieldsNameToSave = () => {
    return [
      "account",
      "id",
      "inputer_name",
      "offer_accept_rr",
      "offer_comments",
      "offer_content",
      "offer_date_send_om",
      "offer_date_terminated",
      "offer_discount_kind",
      "offer_discount_visibility",
      "offer_exclusion",
      "offer_internal",
      "offer_k_note",
      "offer_nr",
      "offer_internal",
      "offer_om_note",
      "offer_rr_note",
      "offer_subcost",
      "offer_subcost_bd",
      "offer_title",
      "owner",
      "receiver",
      "receiver_user",
    ];
  };


  return {
    calculateElement,
    calculateMarginProcOnMarginPlnInput,
    calculateDiscountProcOnDiscountPlnInput,
    getFieldsNameToSave,
    summaryElementsFields,
    getNumberOfNotDeletedRows,
    setServiceOfferDataAfterChange,
    setPartOfferDataAfterChange,
    setInititalServiceData,
    setInititalPartData,
    isTableValid,
    calculateItemsTotalCost,
    getOfferFieldsNameToSave 
  };
}
