import { useCallback, useMemo } from "react";
import { isEmptyValue } from "../helpers/methods";

const useField = () => {

    const getInputWidth = (value, isDivField = false) => {
        if (!isEmptyValue(value)) {
            return `${value.toString().length * 8 + (isDivField ? 0 : 2)}px`
        }
        return undefined
    }

    const inputStyleTable = useMemo(() => {
        return { padding: "3px 6px", height: "46px" }
    }, [])

    const setFocusForFieldById = useCallback((id) => {
        // const field = document.getElementById(id)
        // if (field) {
        //     const isAutocomplete =
        //         field.tagName === "DIV" && field.classList.contains("MuiAutocomplete-root");

        //     if(isAutocomplete){
        //         field.click()
        //         const autocompleteInstance = field.querySelector('input');

        //         // Sprawdź, czy Autocomplete ma zdefiniowaną funkcję select
        //         if (autocompleteInstance) {
        //           // Wykonaj akcję select
        //         //   autocompleteInstance.focus();
        //         }
        //     }
        //     else{

        //         field.focus()
        //     }
        // }
    }, [])


    return {
        getInputWidth,
        inputStyleTable,
        setFocusForFieldById
    };
};

export default useField;