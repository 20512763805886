import i18n from "../../../i18n";

const TITLE_DICT = {
  ticket_app: { label: "TICKET_APP", navigateTo: "/" },
  menu: { label: i18n.t("headers.menu") },
  home_page: { label: i18n.t("headers.home_page") },
  ticket_management: { label: i18n.t("headers.ticket_management") },
  order_management: { label: i18n.t("headers.order_management") },
  offer_management: { label: i18n.t("headers.offer_management") },
  my_documents: { label: i18n.t("headers.my_documents") },
  my_tickets: { label: i18n.t("headers.my_tickets") },
  my_offers: { label: i18n.t("headers.my_offers") },
  guarantee_management: { label: i18n.t("headers.guarantee_management") },
  planner: { label: i18n.t("headers.planner") },
  join_offers: { label: i18n.t("headers.join_offers") },
  accept_offers: { label: i18n.t("headers.accept_offers") },
  my_orders: { label: i18n.t("headers.my_orders") },
  my_tasks: { label: i18n.t("headers.my_tasks") },
  task_management: { label: i18n.t("headers.task_management") },
  owners: { label: i18n.t("headers.owners") },
  stocks: { label: i18n.t("headers.stocks") },
  feedback: { label: i18n.t("headers.feedback") },
  material_passport: { label: i18n.t("headers.material_passport") },
  materials_shopping: { label: i18n.t("headers.materials_shopping") },
  grt_report: { label: i18n.t("headers.grt_report") },
  rr_panel: { label: i18n.t("headers.rr_panel") },
  rr_panel_complains: { label: i18n.t("headers.rr_panel_complains") },
  rr_admin: { label: i18n.t("headers.rr_admin") },
  agreements: { label: i18n.t("headers.agreements") },
  agreement_guarantees: { label: i18n.t("headers.agreement_guarantees") },
  correspondence: { label: i18n.t("headers.correspondence_registry") },
  correspondence_admin: { label: i18n.t("headers.correspondence_admin_panel") },
  correspondence_post: { label: i18n.t("headers.correspondence_post") },
  rcp_time_tracking: { label: i18n.t("headers.rcp_time_tracking") },
  admin_users: { label: i18n.t("headers.admin_users") },
  admin_tenants: { label: i18n.t("headers.admin_tenants") },
  admin_locations: { label: i18n.t("headers.admin_locations") },
  warehouses_transaction: { label: i18n.t("headers.warehouses_transaction") },
  buffers: { label: i18n.t("headers.buffers") },
  po_registry: { label: i18n.t("headers.po_registry") },
  admin_std_tickets_orders: {
    label: i18n.t("headers.admin_std_tickets_orders"),
  },
  admin_std_tickets_orders_automat: {
    label: i18n.t("headers.admin_std_tickets_orders_automat"),
  },
  po_invoices: { label: i18n.t("headers.po_invoices") },
  releases: { label: i18n.t("headers.releases") },
  warehouse_admin: { label: i18n.t("headers.warehouse_admin") },
  admin_permissions: { label: i18n.t("headers.user_permissions") },
  tickets_per_time_report: { label: i18n.t("headers.tickets_per_time_report") },
  tickets_per_owner_report: {
    label: i18n.t("headers.tickets_per_owner_report"),
  },
  tickets_per_location_report: {
    label: i18n.t("headers.tickets_per_location_report"),
  },
  orders_by_ticket_category_report: {
    label: i18n.t("headers.orders_by_ticket_category_report"),
  },
  orders_by_sla_report: {
    label: i18n.t("headers.orders_by_sla_report"),
  },
  subcontractors: { label: i18n.t("headers.subcontractors") },
  service_passport: { label: i18n.t("headers.service_passport") },
  tickets_per_day: {
    label: i18n.t("headers.tickets_per_day"),
  },
  tickets_open_close: {
    label: i18n.t("headers.tickets_open_close"),
  },
  feedback_notes: {
    label: i18n.t("headers.feedback_notes"),
  },
  nps_per_building: {
    label: i18n.t("headers.nps_per_building"),
  },
  tickets_per_k: {
    label: i18n.t("headers.tickets_per_k"),
  },
  orders_per_skill: {
    label: i18n.t("headers.orders_per_skill"),
  },
  orders_per_tb: {
    label: i18n.t("headers.orders_per_tb"),
  },
  orders_per_kt: {
    label: i18n.t("headers.orders_per_kt"),
  },
  offers_per_location_report: {
    label: i18n.t("headers.offers_per_location_report"),
  },
  offers_per_status_report: {
    label: i18n.t("headers.offers_per_status_report"),
  },
  offers_per_report_type_report: {
    label: i18n.t("headers.offers_per_report_type_report"),
  },
  item_tree: {
    label: i18n.t("headers.item_tree"),
  },
  tickets_per_response_time: {
    label: i18n.t("headers.tickets_per_response_time"),
  },
  icos : { label: i18n.t("headers.ico_registry"),},
  docs_restricted: {
    label: i18n.t("headers.docs_restricted"),
  },
  ico_accept : { label: i18n.t("headers.ico_accept"),},
};


export default TITLE_DICT;
