import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useAsync } from "../../../hooks/useAsync";
import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import AutocompleteField from "../../field/AutocompleteField";
import { Button, Grid } from "@mui/material";
import useUserService from "../../../services/userService";
import { useForm } from "../../../hooks/useForm";
import { getTranslatedList, isEmptyValue } from "../../../helpers/methods";
import TextFieldFormControl from "../../field/TextFieldFormControl";



const AddParticipiantDialog = (props) => {
  const { t } = useTranslation();

  const participiantKinds = React.useMemo(() => {
    return [
      "accept",
      "informed",
      "doer"
    ]
  }, [])


  const {
    formValue,
    setFormValue,
    onChange,
    onChangeAutocompleteFieldWithObjectOptions
  } = useForm({
    ico: props.icoId,
    icopart_is_default_participant: false
  })
  
  const requiredFields = [
    "icopart_person",
    "participiant_kind"
  ];
  const isValid = formValue
  ? requiredFields.every(
      (fieldName) => !isEmptyValue(formValue[fieldName])
    )  : false;

  const { getUserSelectList } = useUserService();

  const userSelectList = useAsync(
    () => getUserSelectList(props.searchParams));

  const isLoading = userSelectList.loading

  const onChangeParticipiantKind = useCallback(
    (e, value_object, value_key, state_value_name) => {
      let value = value_object[value_key]
      setFormValue((prev)=>({
        ...prev,
        icopart_role_acceptor : value === "accept",
        icopart_role_informed : value === "informed",
        icopart_role_doer : value === "doer",

      }))
      return onChangeAutocompleteFieldWithObjectOptions(e, value_object, value_key, state_value_name)
    },
    []
  );


  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      maxWidth="sm"
      title={t("dialog.add_participiant_dialog.add_participiant")}
    >
      <LoaderWrapper showLoader={isLoading} >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item xs={6}>
            <AutocompleteField
              name="icopart_person"
              label={t("dialog.add_participiant_dialog.user")}
              value={formValue?.icopart_person}
              options={userSelectList ? userSelectList.value : []}
              isObjectOption={true}
              optionLabelKey={"full_name"}
              addNewValue={false}
              onChange={onChangeAutocompleteFieldWithObjectOptions}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <AutocompleteField
              name="participiant_kind"
              label={t("dialog.add_participiant_dialog.participiant_kind")}
              value={formValue?.participiant_kind}
              options={getTranslatedList(participiantKinds, "dialog.add_participiant_dialog.")}
              isObjectOption={true}
              optionLabelKey={"name"}
              addNewValue={false}
              onChange={onChangeParticipiantKind}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldFormControl
              name="icopart_note"
              multiline
              rows={3}
              resize={"vertical"}
              value={formValue?.icopart_note}
              label={t("dialog.add_participiant_dialog.note")}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              sx={{ marginTop: "20px" }}
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => props.onAddParticipiant(formValue)}
              disabled={!isValid }
            >
              {t("confirm")}
            </Button>
          </Grid>
        </Grid>
      </LoaderWrapper>
    </BasicDialog>
  );
};

AddParticipiantDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  searchParams: PropTypes.string,
  returnObject: PropTypes.bool
};

AddParticipiantDialog.defaultProps = {
  searchParams: "",
  returnObject: false
};

export default AddParticipiantDialog;
