export const PREVIEW_MODE_FIELDS = []

export const EDIT_MODE_FIELDS = [
"ico_product",
"ico_agreement",
"ico_agreement_parent",
"ico_sign_form",
"ico_name",
"ico_note_finance",
"ico_note_legal",
"ico_location",
"participiants_informed",
"docs"
]

export const CHANGE_COND_WO_APPENDIX = [
    "ico_date_repayment",
    "ico_note_finance",
    "schedules"
]

export const CHANGE_AGREEMENT_SIDES = [
    "lender",
    "borrower",
    "ico_note_finance"
]