import { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import OfferTable from "../../table/OfferTable/OfferTable";
import OfferDocumentDialog from "../OfferDocumentDialog";
import useFileService from "../../../services/fileService";
import useOfferService from "../../../services/offerService";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import useDialog from "../../../hooks/useDialog";
import usePaginationWithSearchParams from "../../../hooks/usePaginationWithSearchParams";
import { MY_OFFER_TABLE_FIELDS_CONFIG, SETTLEMENT_OM_TABLE_FIELDS_CONFIG } from "../../../page/MyOffersAcceptPage/MyOffersTablesConfig";
import { isEmptyValue, isSearchParamsEmpty } from "../../../helpers/methods";
import OfferRejectDialog from "../OfferRejectDialog";
import OfferSettlementDialog from "../OfferSettlementDialog/OfferSettlementDialog";
import useAcceptRejectOffers from "../../../hooks/useAcceptRejectOffers";
import OfferHistoryLogDialog from "../OfferHistoryLogDialog/OfferHistoryLogDialog";
import useDialogWithId from "../../../hooks/useDialogWithId";

function MyOffersForTicketDialog(props) {
  const { t } = useTranslation();
  const [countRecords, setCountRecords] = useState();
  const [offerDataLocaly, setOfferDataLocaly] = useState();
  const [isPoRequired, setPoRequired] = useState(false)

  const selectedOfferDataRef = useRef();
  const enclosuresForSelectedOfferRef = useRef();

  const [
    openOfferDocumentDialog,
    onOpenOfferDocumentDialog,
    onCloseOfferDocumentDialog,
  ] = useDialog();

  const [
    openLogDialog,
    historyLogOfferId,
    onOpenLogDialog,
    handleCloseLogDialog,
    exportStatus
  ] = useDialogWithId();

  const {
    page: offerDataPage,
    pageSize: offerDataPageSize,
    handleChangePageWithSearchParams: handleChangeOfferDataPage,
    handleChangePageSizeWithSearchParams: handleChangeOfferDataPageSize,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams();

  const offerData = useAsync(
    () => getMyOfferListByTicket(props.ticketId, searchParams),
    [searchParams]
  );


  const {
    onSendMessageToOffer,
    handleSendAcceptOffer,
    handleSendRejectOffer,
    openSettlementOfferDialog,
    onOpenSettlementOfferDialog,
    openRejectOfferDialog,
    onOpenRejectOfferDialog,
    handleCloseRejectOfferDialog,
    handleCloseSettlementOfferDialog,
    existsChanges,
    offerSettlementData,
    onChangeSettlementTableData,
    offerDetailsLoading,
    isPoNumberValid
  } = useAcceptRejectOffers(
    selectedOfferDataRef.current ? selectedOfferDataRef.current.id: undefined,
    selectedOfferDataRef.current ? selectedOfferDataRef.current.totalItemCost : undefined,
    offerData.refetch,
    onCloseOfferDocumentDialog,
    SETTLEMENT_OM_TABLE_FIELDS_CONFIG,
    selectedOfferDataRef.current ? selectedOfferDataRef.current.isOfferCR : false,
  )

  const {
    getMyOffersFilteringData,
    getMyOfferListByTicket, } =
    useOfferService();


  const offerFilteringData = useAsync(getMyOffersFilteringData);

  const {
    getEnclosuresForItemData,
    handleDownloadEnclosure,
    handlePreviewEnclosure,
  } = useFileService();

  const getEnclosuresForItemDataFn = useAsyncFn(getEnclosuresForItemData);

  useEffect(() => {
    if (offerData.loading) {
      return;
    }
    setOfferDataLocaly(offerData.value.results);
    setCountRecords(offerData.value.count);
  }, [offerData.loading]);


  const handleClickRow = (offerId) => {
    selectedOfferDataRef.current = { id: offerId };
    let selectedOffer = offerDataLocaly.find(o => o.id === offerId)
    if (selectedOffer) {
      selectedOfferDataRef.current['readOnly'] = !selectedOffer.is_allowed_to_accept
      selectedOfferDataRef.current['isOfferCR'] = selectedOffer.is_cr_requested
      selectedOfferDataRef.current['has_crs'] = selectedOffer.has_crs
      selectedOfferDataRef.current['totalItemCost'] = setTotalDataCostDict(selectedOffer);
    }

    getEnclosuresForItemDataFn
      .execute(offerId, undefined, "offer")
      .then((res) => {
        enclosuresForSelectedOfferRef.current = res;
        onOpenOfferDocumentDialog();
      });
  };
  const setTotalDataCostDict = (offer) => {
    let totalCostData = {};
    totalCostData.items_quotation_net = offer.offer_quatation_net;
    totalCostData.items_quotation_vat = offer.offer_quatation_vat;
    totalCostData.items_quotation_gross = offer.offer_quatation_gross;
    return [totalCostData];
  };

  const isLoading =
    offerFilteringData.loading ||
    offerDataLocaly === undefined ||
    countRecords === undefined;



  return (
    <BasicDialog
      open={props.open}
      onClose={() => props.onClose(existsChanges)}
      titleAlign="center"
      contentAlign="center"
      title={`${t("dialog.offers_for_ticket_dialog.offers_for_ticket")} ${props.ticketNumber
        }`}
      maxWidth="xl"
    >
      <LoaderWrapper showLoader={isLoading}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          rowGap={3}
        >
          <Grid item xs={12}>
            <OfferTable
              data={offerDataLocaly}
              showCheckbox={false}
              countRecords={countRecords}
              page={offerDataPage}
              onPageChange={handleChangeOfferDataPage}
              rowsPerPage={offerDataPageSize}
              onClickRow={handleClickRow}
              onRowsPerPageChange={handleChangeOfferDataPageSize}
              resetPageNumber={resetPageNumber}
              filteringData={offerFilteringData}
              hiddenColumns={[]}
              showTitle={false}
              tableConfig={MY_OFFER_TABLE_FIELDS_CONFIG}
              showContextMenu={false}
              initialExpandedFilterHeader={!isSearchParamsEmpty(searchParams)}
            />
          </Grid>
          {openOfferDocumentDialog && (
            <OfferDocumentDialog
              open={openOfferDocumentDialog}
              onClose={onCloseOfferDocumentDialog}
              validatedOffer={selectedOfferDataRef.current}
              enclosures={enclosuresForSelectedOfferRef.current}
              onDownloadEnclosure={handleDownloadEnclosure}
              onPreviewEnclosure={handlePreviewEnclosure}
              onRejectOfferByOm={selectedOfferDataRef.current.isOfferCR? () =>handleSendRejectOffer({}): onOpenRejectOfferDialog}
              onAcceptOfferByOm={onOpenSettlementOfferDialog}
              showBottomButtonGrid={true}
              readOnly={selectedOfferDataRef.current.readOnly && !selectedOfferDataRef.current.isOfferCR}
              isOfferCR = {selectedOfferDataRef.current.isOfferCR}
              isMyOfferPage={true}
              onOpenHistoryLogDialog = {onOpenLogDialog}
            />
          )}
          {openRejectOfferDialog && (
            <OfferRejectDialog
              open={openRejectOfferDialog}
              onClose={handleCloseRejectOfferDialog}
              onSubmit={handleSendRejectOffer}
              loading={offerDetailsLoading}
              isOfferCR = {selectedOfferDataRef.current.isOfferCR}
            />
          )}

          {openSettlementOfferDialog && (
            <OfferSettlementDialog
              open={openSettlementOfferDialog}
              onClose={handleCloseSettlementOfferDialog}
              data={offerSettlementData}
              readOnly={selectedOfferDataRef.current.readOnly && !selectedOfferDataRef.current.isOfferCR}
              showAddButton={false}
              showContextMenu={false}
              onDataChange={onChangeSettlementTableData}
              isTableValid={true}
              isSumValid={true}
              tableConfig={SETTLEMENT_OM_TABLE_FIELDS_CONFIG}
              showConfirmationForm={!selectedOfferDataRef.current.readOnly  || selectedOfferDataRef.current.isOfferCR}
              onConfirmAcceptOfferByOm={handleSendAcceptOffer}
              onSendMessage={onSendMessageToOffer}
              loading={offerDetailsLoading}
              isOfferCR = {selectedOfferDataRef.current.isOfferCR}
              isPoNumberValid={isPoNumberValid}
            />
          )}
          
      {openLogDialog &&
        <OfferHistoryLogDialog
          offerId={historyLogOfferId}
          open={openLogDialog}
          onClose={handleCloseLogDialog}
        />
      }
        </Grid>
      </LoaderWrapper>
    </BasicDialog>
  );
}

MyOffersForTicketDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  ticketId: PropTypes.string,
  ticketNumber: PropTypes.number,
};
MyOffersForTicketDialog.defaultProps = {
  open: false,
};

export default MyOffersForTicketDialog;
