import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import AutocompleteField from "../../field/AutocompleteField";
import React from "react";
import { getTranslatedList } from "../../../helpers/methods";
import DateField from "../../field/DateField/DateField";
import { ICO_AGREEMENT_CONTRACT } from "../../../helpers/constants";

const IcoMainDataForm = React.memo(({ icoData, icoId, isEditableField, readOnlyOwners, filteringData, onChange, onChangeDate, onChangeAutocomplete, onChangeCustomAutocomplete, parentAgreementDisabled }) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item xs={2}>
            <TextFieldFormControl
              name="ico_nr"
              readOnly={true}
              value={icoData?.ico_nr}
              label={t("form.ico_main_data_form.ico_nr")}
            />
          </Grid>
          <Grid item xs={2}>
            <AutocompleteField
              name={"ico_product"}
              label={t("form.ico_main_data_form.product")}
              value={icoData?.ico_product}
              options={filteringData?.products}
              isObjectOption={true}
              optionLabelKey={"icoprod_name"}
              onChange={onChangeAutocomplete}
              addNewValue={false}
              multiple={false}
              readOnly={!isEditableField("ico_product")}
              required
            />
          </Grid>

          <Grid item xs={2}>
            <AutocompleteField
              name={"ico_agreement"}
              label={t("form.ico_main_data_form.agreement_kind")}
              value={icoData?.ico_agreement}
              options={getTranslatedList(filteringData?.agreement_kinds, "form.ico_main_data_form.")}
              isObjectOption={true}
              optionLabelKey={"name"}
              multiple={false}
              addNewValue={false}
              readOnly={!isEditableField("ico_agreement")}
              onChange={onChangeCustomAutocomplete}
            />
          </Grid>

          <Grid item xs={2}>
            <AutocompleteField
              name={"draft"}
              label={t("form.ico_main_data_form.draft")}
              value={icoData?.draft}
              options={filteringData?.drafts}
              isObjectOption={true}
              optionLabelKey={"draft_name"}
              onChange={onChangeAutocomplete}
              addNewValue={false}
              multiple={false}
              readOnly={!isEditableField("draft")}
            />
          </Grid>
          <Grid item xs={2}>
            <AutocompleteField
              name={"ico_sign_form"}
              label={t("form.ico_main_data_form.sign_form")}
              value={icoData?.ico_sign_form}
              options={getTranslatedList(filteringData?.sign_forms, "form.ico_main_data_form.")}
              isObjectOption={true}
              optionLabelKey={"name"}
              multiple={false}
              addNewValue={false}
              readOnly={!isEditableField("ico_sign_form")}
              onChange={onChangeAutocomplete}
            />
          </Grid>
          <Grid item xs={2}>
            <TextFieldFormControl
              name="ico_name"
              value={icoData?.ico_name}
              onChange={onChange}
              readOnly={!isEditableField("ico_name")}
              label={t("form.ico_main_data_form.agreement_name")}
              required
            />
          </Grid>
          <Grid item xs={2}>
            <AutocompleteField
              name={"ico_agreement_parent"}
              label={t("form.ico_main_data_form.main_agreement")}
              value={icoData?.ico_agreement_parent}
              options={filteringData?.icos}
              isObjectOption={true}
              optionLabelKey={"ico_nr"}
              onChange={onChangeAutocomplete}
              addNewValue={false}
              multiple={false}
              readOnly={!isEditableField("ico_agreement_parent") || parentAgreementDisabled}
              required={!parentAgreementDisabled}
            />
          </Grid>
          <Grid item xs={2}>
            <AutocompleteField
              name={"lender"}
              label={t("form.ico_main_data_form.lender")}
              value={icoData?.lender}
              options={filteringData?.owners}
              isObjectOption={true}
              optionLabelKey={"owner_short_name"}
              onChange={onChangeCustomAutocomplete}
              addNewValue={false}
              multiple={false}
              required
              readOnly={!isEditableField("lender")|| readOnlyOwners}
            />
          </Grid>
          
          <Grid item xs={2}>
            <AutocompleteField
              name={"borrower"}
              label={t("form.ico_main_data_form.borrower")}
              value={icoData?.borrower}
              options={filteringData?.owners}
              isObjectOption={true}
              optionLabelKey={"owner_short_name"}
              onChange={onChangeCustomAutocomplete}
              addNewValue={false}
              multiple={false}
              required
              readOnly={!isEditableField("borrower")|| readOnlyOwners}
            />
          </Grid>
          <Grid item xs={2}>
            <DateField
              name="ico_date_agreement"
              label={t("form.ico_main_data_form.ico_date_agreement")}
              value={icoData?.ico_date_agreement}
              onChange={onChangeDate}
              readOnly={!isEditableField("ico_date_agreement")}
            />
          </Grid>
          <Grid item xs={2}>
            <DateField
              name="ico_date_activation"
              label={t("form.ico_main_data_form.ico_date_activation")}
              value={icoData?.ico_date_activation}
              onChange={onChangeDate}
              required
              readOnly={!isEditableField("ico_date_activation")}
              minDate={icoData?.minActivationDate}
              maxDate={icoData?.maxActivationDate}
            />
          </Grid>
          <Grid item xs={2}>
            <DateField
              name="ico_date_repayment"
              label={t("form.ico_main_data_form.ico_date_repayment")}
              value={icoData?.ico_date_repayment}
              onChange={onChangeDate}
              required
              readOnly={!isEditableField("ico_date_repayment")}
              minDate={icoData?.minRepaymentDate}
            />
          </Grid>
        </Grid>
      </Grid>


      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item xs={4}>
            <TextFieldFormControl
              name="ico_agreement_reason"
              value={icoData?.ico_agreement_reason}
              readOnly={icoData?.ico_agreement === ICO_AGREEMENT_CONTRACT || !isEditableField("ico_agreement_reason")}
              label={t("form.ico_main_data_form.ico_agreement_reason")}
              onChange={onChange}
              multiline
              rows={3}
              resize={"vertical"}
            />
          </Grid>
          <Grid item xs={4}>
            <TextFieldFormControl
              name="ico_note_finance"
              value={icoData?.ico_note_finance}
              label={t("form.ico_main_data_form.ico_note_finance")}
              onChange={onChange}
              readOnly={!isEditableField("ico_note_finance")}
              multiline
              rows={3}
              resize={"vertical"}
            />
          </Grid>
          <Grid item xs={4}>
            <TextFieldFormControl
              name="ico_note_legal"
              value={icoData?.ico_note_legal}
              label={t("form.ico_main_data_form.ico_note_legal")}
              onChange={onChange}
              readOnly={!isEditableField("ico_note_legal")}
              multiline
              rows={3}
              resize={"vertical"}
            />
          </Grid>
        </Grid>
      </Grid>



      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >{icoId && <>
          <Grid item xs={4}>
            <TextFieldFormControl
              value={icoData?.ico_status}
              readOnly={true}
              label={t("form.ico_main_data_form.status")}
            />
          </Grid>
          <Grid item xs={4}>
            <TextFieldFormControl
              name="ico_location"
              value={icoData?.ico_location}
              onChange={onChange}
              label={t("form.ico_main_data_form.ico_location")}
              readOnly={!isEditableField("ico_location")}
            />
          </Grid>
        </>
          }
        </Grid>
      </Grid>
    </Grid>
  );
});

IcoMainDataForm.propTypes = {
  readOnly: PropTypes.bool,
  icoData: PropTypes.object,
  icoId: PropTypes.string,
  onChange: PropTypes.func,
  onChangeDate: PropTypes.func,
  onChangeAutocomplete: PropTypes.func,
  onChangeCustomAutocomplete: PropTypes.func,
  filteringData: PropTypes.object,
  parentAgreementDisabled: PropTypes.bool
};

IcoMainDataForm.defaultProps = {
  readOnly: false,
  filteringData: { value: [] },
  parentAgreementDisabled: true
};

export default IcoMainDataForm;
