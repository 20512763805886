import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import { useTranslation } from "react-i18next";
import React, { useCallback } from "react";
import DateField from "../../field/DateField/DateField";
import IconButtonWithTooltip from "../../button/IconButtonWithTooltip";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

function areRowDataPropsEqual(oldProps, newProps) {
  return (
    oldProps.id === newProps.id &&
    oldProps.full_name === newProps.full_name &&
    oldProps.note === newProps.note &&
    oldProps.icopart_is_default_participant === newProps.icopart_is_default_participant &&
    oldProps.icopart_date_realization === newProps.icopart_date_realization &&
    oldProps.icopart_date_accept === newProps.icopart_date_accept &&
    oldProps.icopart_date_send  === newProps.icopart_date_send &&
    oldProps.readOnly === newProps.readOnly
  );
}

const ParticipiantForm  = React.memo(({
  index,
  full_name,
  note,
  icopart_date_realization,
  icopart_date_accept,
  icopart_date_send,
  icopart_date_reject,
  onEditData,
  onRemoveData,
  readOnly,
  kind,
  showDates }
) => {
  const { t } = useTranslation();



const onEditDataLoc = useCallback((e)=>{
  onEditData( e, index)
},[index])


const onRemoveDataLoc = useCallback((e)=>{
  e.stopPropagation();
  onRemoveData(index)
},[index])

  const getDate = () => {
    switch (kind) {
      case "accept":
        return icopart_date_accept
      case "informed":
        return icopart_date_send
      case "doer":
        return icopart_date_realization
    }
  }


  const getDateField = () =>{
    if (kind === "accept" && icopart_date_reject !== undefined){
      return(
        <DateField
        value={icopart_date_reject}
        label={t(`other.ico_participiant_box.ico_date_reject`)}
        readOnly={true}
        isInvalid
        
      />
      )
    }else{
      return(
      <DateField
      value={getDate()}
      label={t(`other.ico_participiant_box.ico_date_${kind}`)}
      readOnly={true}
    />)
    }

  }

return (
  <Grid
    container
    direction="row"
    justifyContent="flex-start"
    alignItems="flex-start"
    spacing={1}
  >
    <Grid item xs={4} >
      <TextFieldFormControl
        value={full_name}
        label={t(`other.ico_participiant_box.${kind}`)}
        readOnly={true}
      />
    </Grid>
     {showDates && kind !== 'doer' &&
      <Grid item xs={3} >
       {getDateField()}
      </Grid>} 
    <Grid item xs={showDates && kind !== 'doer' ? 4 : 7} >
      <TextFieldFormControl
        name={"icopart_note"}
        value={note}
        label={t(`other.ico_participiant_box.icopart_note`)}
        readOnly={readOnly}
        resize={"vertical"}
        multiline
        rows={1}
        onChange={onEditDataLoc}
      />
    </Grid>
   {!readOnly &&
      <Grid item xs={1}>
        <IconButtonWithTooltip
          onClick={onRemoveDataLoc}
          title={t(`other.ico_participiant_box.remove_${kind}`)}
        >
          <RemoveCircleIcon color="secondary" />
        </IconButtonWithTooltip>
      </Grid>
    } 
  </Grid>
  );
}, areRowDataPropsEqual);

ParticipiantForm.propTypes = {
  index : PropTypes.number,
  element : PropTypes.object,
  onEditData : PropTypes.func,
  onRemoveData : PropTypes.func,
  readOnly : PropTypes.bool,
  kind : PropTypes.string,
  showDates : PropTypes.bool
};

ParticipiantForm.defaultProps = {
  readOnly : true,
  showDates : true
};

export default ParticipiantForm;
