import moment from "moment";
import {
  convertEmptyValueToZero,
  isEmptyArray,
  returnDateObject,
  roundPrice
} from "../helpers/methods";
import DateService from "./dateService";

export default function useIcoScheduleCalculationService() {

  const calculateNewConditionValueInPLN = (currencyValue, conditionValue) => {
    return roundPrice(convertEmptyValueToZero(currencyValue) * convertEmptyValueToZero(conditionValue))
  }

  const calculateNewSchedule = (ico_date_activation, ico_date_repayment, schedulesData, conditionData, interestRateValue, areTranchesChangedByUser, areInstallmentsChangedByUser, areInterestsChangedByUser) => {
    let updatedScheduleData = { ...schedulesData }
    if (!areTranchesChangedByUser) {
      updatedScheduleData["tranche"] = setNewSchedulesTranches(ico_date_activation, ico_date_repayment, conditionData.condition_value, conditionData.disburement_amount, "tranche", schedulesData["tranche"])
    }
    if (!areInstallmentsChangedByUser) {
      updatedScheduleData["installment"] = setNewSchedulesTranches(ico_date_activation, ico_date_repayment, conditionData.condition_value, conditionData.settlement_amount, "installment", schedulesData["installment"])
    }

    updatedScheduleData["interest"] = setInterestPaymentDates(ico_date_activation, ico_date_repayment, conditionData.condition_payment, areInterestsChangedByUser, updatedScheduleData["interest"], updatedScheduleData["installment"])


    return updatedScheduleData;
  }

  const setNewSchedulesTranches = (ico_date_activation, ico_date_repayment, condition_value, amount, scheduleType, oldValues) => {
    let startDate = new Date(ico_date_activation);
    let schedulesTranches = []
    let diffInDays = moment(ico_date_repayment).diff(moment(ico_date_activation), 'days');
    let dayInterval = amount > 1 ? Math.round(diffInDays / (amount)) : diffInDays
    let payInterval = roundPrice(condition_value / amount)
    console.log('old', oldValues)
    if (scheduleType === "tranche") {
      for (let i = 0; i < amount; i++) {
        if (isEmptyArray(oldValues) || oldValues[i]?.editable ) {
          schedulesTranches.push({
            icoschedule_amount_to_pay: i === amount - 1 ? roundPrice(condition_value - roundPrice(payInterval * (amount - 1))) : payInterval,
            icoschedule_due_date: getNextDate(startDate, i > 0 ? dayInterval : 0),
            editable: true,
            editDate: Date.now(),
            type: scheduleType
          })
        } else {
          schedulesTranches.push(oldValues[i])
        }
      }
    } else {
      for (let i = 0; i < amount; i++) {
        if (isEmptyArray(oldValues)  || oldValues[i]?.editable) {
          schedulesTranches.push({
            icoschedule_amount_to_pay: i === amount - 1 ? roundPrice(condition_value - roundPrice(payInterval * (amount - 1))) : payInterval,
            icoschedule_due_date: i === amount - 1 ? new Date(ico_date_repayment) : getNextDate(startDate, dayInterval + 1),
            editable: true,
            editDate: Date.now(),
            type: scheduleType
          })
        } else {
          schedulesTranches.push(oldValues[i])
        }
      }
    }

    return schedulesTranches
  }

  const setInterestPaymentDates = (ico_date_activation, ico_date_repayment, conditionPayment, areInterestsChangedByUser, currentSchedule, installmentDates) => {
    let schedulesTranches = []
    let dates = []
    if (areInterestsChangedByUser) {
      dates = currentSchedule.map((schedule) => schedule.icoschedule_due_date)
    } else if (conditionPayment === "tranche") {
      dates = installmentDates.map((installment) => installment.icoschedule_due_date)
    }
    else {
      dates = conditionPayment === "end" ? [] : getLastDaysBetweenDatesDependsOnPeriod(ico_date_activation, ico_date_repayment, conditionPayment)
    }


    for (let i = 0; i < dates.length; i++) {
      if(isEmptyArray(currentSchedule)  || currentSchedule[i]?.editable) {
        schedulesTranches.push({
          icoschedule_start_date: i > 0 ? dates[i - 1] : new Date(ico_date_activation),
          icoschedule_due_date: dates[i],
          editable: true,
          type: "interest"
        })
      }else{
        schedulesTranches.push(currentSchedule[i])
      }
    }
    if (!areInterestsChangedByUser && conditionPayment !== "tranche") {

      schedulesTranches.push({
        icoschedule_start_date: dates[dates.length - 1] !== undefined ? dates[dates.length - 1] : new Date(ico_date_activation),
        icoschedule_due_date: new Date(ico_date_repayment),
        editable: true,
        type: "interest"
      })

    }

    return schedulesTranches
  }

  const setPaymentDaysForIntersts = (ico_date_activation, ico_date_repayment, interestsSchedule, currentConditionRanges, marginProc, interestBaseProc) => {
    let date_repayment_obj = new Date(ico_date_repayment)

    if (interestsSchedule.length === 0) {
      return interestsSchedule
    } else if (currentConditionRanges.length === 1 && interestsSchedule.length === 1) {
      let scheduleItem = interestsSchedule[0]
      scheduleItem.editDate = Date.now()
      let diffDays = moment(ico_date_repayment).diff(moment(ico_date_activation), 'days')
      let calculationValue = currentConditionRanges[0].currentConditionValue
      scheduleItem.calcsData = [{
        part_text_control: `liczba dni ${diffDays},  data koniec ${ico_date_repayment}, kwota bazowa ${calculationValue}`,
        part_icoschedule_amount_to_pay: calculateInterestPayment(diffDays, marginProc, interestBaseProc, calculationValue, currentConditionRanges[0].daysInYear)
      }]

      return [{ ...calculateFullInterestRate(scheduleItem) }]
    } else {
      let updatedSchedules = []
      for (let i = 0; i < interestsSchedule.length; i++) {
        let item = { ...interestsSchedule[i] }
        let startIndex = 0;
        let endIndex = 0;
        let searchAnotherEnd = true
        for (let j = 0; j < currentConditionRanges.length; j++) {
          let scheduleDate = { ...currentConditionRanges[j] }

          if (item.icoschedule_start_date >= scheduleDate.startDate && i > 0) {
            startIndex = j
          }

          if (item.icoschedule_due_date <= scheduleDate.endDate && searchAnotherEnd) {
            endIndex = j
            searchAnotherEnd = false
          }
        }

        item.startIndex = startIndex
        item.endIndex = endIndex

        item.editDate = Date.now()
        if (startIndex === endIndex || interestsSchedule[i].icoschedule_start_date.getTime() === currentConditionRanges[startIndex].endDate.getTime()) {
          let startDate = DateService.convertDateToFormatYYYYMMDD(interestsSchedule[i].icoschedule_start_date)
          let endDate = DateService.convertDateToFormatYYYYMMDD(interestsSchedule[i].icoschedule_due_date)
          let diffDays = moment(endDate).diff(moment(startDate), 'days');
          if (i === 0) {
            diffDays = diffDays + 1 //include repayment day
          }
          if (i === interestsSchedule.length - 1) {
            diffDays = diffDays - 1 //exclude repayment day
          }
          let calculationValue = currentConditionRanges[startIndex].currentConditionValue
          item.calcsData = [{
            part_text_control: `liczba dni ${diffDays}, data koniec ${endDate}, kwota bazowa ${calculationValue}`,
            part_icoschedule_amount_to_pay: calculateInterestPayment(diffDays, marginProc, interestBaseProc, calculationValue, currentConditionRanges[startIndex].daysInYear)
          }]
        } else {
          let startDate = DateService.convertDateToFormatYYYYMMDD(interestsSchedule[i].icoschedule_start_date)
          let endDate = DateService.convertDateToFormatYYYYMMDD(currentConditionRanges[startIndex].endDate)
          let firstRangeDiffDays = moment(endDate).diff(moment(startDate), 'days');

          if (i !== 0 && firstRangeDiffDays > 1) {
            firstRangeDiffDays = firstRangeDiffDays - 1 //include activation date
          }

          let calculationValue = currentConditionRanges[startIndex].currentConditionValue
          let calcsData = [{
            part_text_control: `liczba dni ${firstRangeDiffDays},  data koniec ${endDate}, kwota bazowa ${calculationValue}`,
            part_icoschedule_amount_to_pay: calculateInterestPayment(firstRangeDiffDays, marginProc, interestBaseProc, calculationValue, currentConditionRanges[startIndex].daysInYear)
          }]
          for (let k = startIndex + 1; k < endIndex; k++) {
            startDate = DateService.convertDateToFormatYYYYMMDD(currentConditionRanges[k].startDate)
            endDate = DateService.convertDateToFormatYYYYMMDD(currentConditionRanges[k].endDate)
            let diffDays = moment(endDate).diff(moment(startDate), 'days');
            calculationValue = currentConditionRanges[k].currentConditionValue
            calcsData.push({
              part_text_control: `liczba dni ${diffDays},  data koniec ${endDate}, kwota bazowa ${calculationValue}`,
              part_icoschedule_amount_to_pay: calculateInterestPayment(diffDays, marginProc, interestBaseProc, calculationValue, currentConditionRanges[k].daysInYear)
            })
          }
          startDate = DateService.convertDateToFormatYYYYMMDD(currentConditionRanges[endIndex].startDate)
          endDate = DateService.convertDateToFormatYYYYMMDD(interestsSchedule[i].icoschedule_due_date)
          let diffDays = moment(endDate).diff(moment(startDate), 'days');

          if (date_repayment_obj.getTime() === interestsSchedule[i].icoschedule_due_date.getTime()) {
            diffDays = diffDays - 1 //exclude repayment day
          }
          calculationValue = currentConditionRanges[endIndex].currentConditionValue
          calcsData.push({
            part_text_control: `liczba dni ${diffDays},  data koniec ${endDate}, kwota bazowa ${calculationValue}`,
            part_icoschedule_amount_to_pay: calculateInterestPayment(diffDays, marginProc, interestBaseProc, calculationValue, currentConditionRanges[endIndex].daysInYear)
          })
          item.calcsData = calcsData
        }

        updatedSchedules.push({ ...calculateFullInterestRate(item) })

      }
      return updatedSchedules
    }

  }

  const preprareRangesWithCurrentCondition = (transheSchedules, installmentSchedules) => {
    let unsortedRanges = [...transheSchedules, ...installmentSchedules]
    let sortedRanges = unsortedRanges.sort((x, y) => {
      return x.icoschedule_due_date - y.icoschedule_due_date;
    });

    let dateRanges = []

    let unsortedRangesWithLeapYears = [...sortedRanges]

    if (sortedRanges[0].icoschedule_due_date.getFullYear() !== sortedRanges[sortedRanges.length - 1].icoschedule_due_date.getFullYear()) {
      for (let i = 0; i < sortedRanges.length - 1; i++) {
        let leapYears = getLeapYearsBetween(sortedRanges[i].icoschedule_due_date, sortedRanges[i + 1].icoschedule_due_date)
        for (let year of leapYears) {
          if (sortedRanges[i].icoschedule_due_date.getFullYear() <= year - 1) {
            unsortedRangesWithLeapYears.push({
              icoschedule_amount_to_pay: 0,
              icoschedule_due_date: new Date(`${year - 1}-12-31`),
              editable: true,
              editDate: Date.now(),
              type: sortedRanges[i].scheduleType
            })
          }

          unsortedRangesWithLeapYears.push({
            icoschedule_amount_to_pay: 0,
            icoschedule_due_date: new Date(`${year}-12-31`),
            editable: true,
            editDate: Date.now(),
            type: sortedRanges[i].scheduleType
          })
        }

      }
    }
    let sortedRangesWithLeapYear = unsortedRangesWithLeapYears.sort((x, y) => {
      return x.icoschedule_due_date - y.icoschedule_due_date;
    });

    for (let i = 0; i < sortedRangesWithLeapYear.length - 1; i++) {
      let delta = sortedRangesWithLeapYear[i].type === "tranche" ? sortedRangesWithLeapYear[i].icoschedule_amount_to_pay : -sortedRangesWithLeapYear[i].icoschedule_amount_to_pay
      dateRanges.push({
        startDate: sortedRangesWithLeapYear[i].icoschedule_due_date,
        endDate: sortedRangesWithLeapYear[i + 1].icoschedule_due_date,
        currentConditionValue: i === 0 ? sortedRangesWithLeapYear[0].icoschedule_amount_to_pay : dateRanges[dateRanges.length - 1].currentConditionValue + delta,
        daysInYear: isLeapYear(sortedRangesWithLeapYear[i + 1].icoschedule_due_date.getFullYear()) ? 366 : 365
      })
    }
    return dateRanges
  }

  const getNextDate = (date, daysInterval) => {
    date.setDate(date.getDate() + daysInterval);
    return new Date(date)
  }


  const calculateInterestPayment = (diffDays, marginProc, interestBaseProc, currentConditionValue, daysInYear) => {
    marginProc = parseFloat(marginProc)
    interestBaseProc = parseFloat(interestBaseProc)
    currentConditionValue = parseFloat(currentConditionValue)

    return diffDays > 0 ? roundPrice(convertEmptyValueToZero(currentConditionValue) * (convertEmptyValueToZero(marginProc + interestBaseProc) / 100) * diffDays / daysInYear) : 0
  }

  const calculateFullInterestRate = (item) => {
    let calculatedItem = { ...item }
    let icoschedule_amount_to_pay = 0

    for (let data of item.calcsData) {
      icoschedule_amount_to_pay = icoschedule_amount_to_pay + data.part_icoschedule_amount_to_pay
    }
    calculatedItem.icoschedule_amount_to_pay = icoschedule_amount_to_pay
    calculatedItem.testText = '\n' + item.calcsData.map(obj => obj.part_text_control).join("; ");
    return calculatedItem
  }

  const getLastDaysBetweenDatesDependsOnPeriod = (startDate, endDate, conditionPayment) => {

    const quarterDates = ["-03-31", "-06-30", "-09-30", "-12-31"]
    const yearDates = ["-12-31"]

    const pad = num => ("0" + num).slice(-2);

    const formatDate = d => d.getFullYear() + "-" + pad(d.getMonth() + 1) + "-" + pad(d.getDate());

    const getLastDayOfMonth = (year, month) => {
      return new Date(year, month + 1, 0);
    }

    const getRange = range => {
      const ranges = [];
      let year = range.start.getFullYear();
      let month = range.start.getMonth();
      const endYear = range.end.getFullYear();
      const endMonth = range.end.getMonth();

      while (year < endYear || (year === endYear && month < endMonth)) {
        const lastDay = getLastDayOfMonth(year, month);
        let formatedDate = formatDate(lastDay)
        switch (conditionPayment) {
          case "month":
            ranges.push(new Date(formatedDate));
            break;
          case "quarter":
            if (quarterDates.some(item => formatedDate.includes(item))) {
              ranges.push(new Date(formatedDate));
            }
            break;
          case "year":
            if (yearDates.some(item => formatedDate.includes(item))) {
              ranges.push(new Date(formatedDate));
            }
            break;
        }
        month++;
        if (month > 11) {
          month = 0;
          year++;
        }
      }

      return ranges;
    };

    let range = {
      start: new Date(startDate),
      end: new Date(endDate)
    };

    return getRange(range)
  }

  const isLeapYear = (year) => {
    return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
  }

  const getLeapYearsBetween = (startDate, endDate) => {
    const startYear = startDate.getFullYear();
    const endYear = endDate.getFullYear();
    const leapYears = [];

    for (let year = startYear; year < endYear; year++) {
      if (isLeapYear(year)) {
        leapYears.push(year);
      }
    }

    return leapYears;
  }

  const checkGroupSumIsEqualConditionValue = (conditionValue, kindElements) => {
    if (kindElements.length === 0) {
      return true
    }
    let groupSum = 0;
    for (let element of kindElements) {
      let value = element.icoschedule_amount_to_pay ? parseFloat(element.icoschedule_amount_to_pay) : 0
      groupSum = groupSum + value
    }
    return conditionValue === groupSum
  }

  const checkGroupDatesRanges = (kindElements, index) => {

    if (kindElements.length < 2) {
      return true
    } else if (index === 0) {
      return returnDateObject(kindElements[1].icoschedule_due_date) > returnDateObject(kindElements[0].icoschedule_due_date)
    } else if (index === kindElements.length - 1) {
      return returnDateObject(kindElements[kindElements.length - 1].icoschedule_due_date) > returnDateObject(kindElements[index - 1].icoschedule_due_date)
    } else {
      return returnDateObject(kindElements[index + 1].icoschedule_due_date) > returnDateObject(kindElements[index].icoschedule_due_date) && returnDateObject(kindElements[index].icoschedule_due_date) > returnDateObject(kindElements[index - 1].icoschedule_due_date)
    }
  }

  const checkFirstInterestDateValid = (installmentDate, interstDate) => {
    return installmentDate <= interstDate
  }

  const checkFirstDateIsAfterActivationValid = (icoActivationDate, date) => {
    date = new Date(DateService.convertDatetimeFromBackendToFormatYYYYMMDD(date))
    return icoActivationDate < date
  }

  const checkPartialInstallmentRatesAreLowerThanPartialTranchesDatesIsValid = (index, chosenInstallmentDate, tranches, installments) => {
    let partialInstalmmentSum = 0
    let partialTranchesSum = 0
    for (let i = 0; i <= index; i++) {
      let value = installments[i]?.icoschedule_amount_to_pay ? parseFloat(installments[i].icoschedule_amount_to_pay) : 0
      partialInstalmmentSum = partialInstalmmentSum + value
    }
    for (let tranche of tranches) {
      if (returnDateObject(tranche.icoschedule_due_date) < chosenInstallmentDate) {
        let value = tranche.icoschedule_amount_to_pay ? parseFloat(tranche.icoschedule_amount_to_pay) : 0
        partialTranchesSum = partialTranchesSum + value
      }
    }
    return partialTranchesSum > partialInstalmmentSum
  }

  return {
    calculateNewSchedule,
    setNewSchedulesTranches,
    calculateNewConditionValueInPLN,
    checkGroupSumIsEqualConditionValue,
    checkGroupDatesRanges,
    checkFirstInterestDateValid,
    checkFirstDateIsAfterActivationValid,
    checkPartialInstallmentRatesAreLowerThanPartialTranchesDatesIsValid
  };
}
