const PlannerService = {
  handlePlannerTablesDragStart: function (e, data) {
    const dragEle = e.target;

    const elem = document.createElement("div");
    elem.style.position = "absolute";
    elem.style.top = "-1000px";
    elem.style.height = "20px";
    elem.style.width = "120px";
    elem.style.background = "var(--light-blue)";
    elem.style.borderRadius = "5px";
    elem.style.color = "#000000de";
    elem.style.fontSize = "12px";
    elem.style.paddingInline = "6px";
    elem.style.display = "flex";

    const svg = document.createElement("svg");
    svg.innerHTML = `<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CalendarMonthOutlinedIcon" style="height: 17.5px;"><path d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zm0-12H5V6h14v2zM9 14H7v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zm-8 4H7v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2z"></path></svg>`;
    elem.appendChild(svg);

    const txt = document.createElement("span");
    txt.style.overflow = "hidden";
    txt.style.textOverflow = "ellipsis";
    const taskLabel = data.find((item) => item.name === "task_planner_label");
    txt.textContent = taskLabel.value;
    elem.appendChild(txt);

    const ghostEle = elem;
    document.body.appendChild(ghostEle);
    e.dataTransfer.setDragImage(ghostEle, -15, 0);

    const handleDragEnd = () => {
      ghostEle.remove();
      dragEle.removeEventListener("dragend", handleDragEnd);
    };

    dragEle.addEventListener("dragend", handleDragEnd);
  },
};

export default PlannerService;
