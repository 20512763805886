import { Button, Grid } from "@mui/material";
import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ParticipiantForm from "../../form/ParticipiantForm";



const IcoParticipiantBox = React.memo((
    { participiants,
        onEditData,
        onRemoveData,
        onAddData,
        readOnly,
        participiantKind,
        showDates,
        allowAddNewInforemdParticipiant }) => {


    const onChangeDataByGroup = useCallback((e, index) => {
        onEditData(e, index, participiantKind)
    }, [participiantKind, onEditData])

    const onAddUserByGroup = useCallback((e) => {
        onAddData(participiantKind)
    }, [participiantKind])

    const onRemoveUserFromGroup = useCallback((index) => {
        onRemoveData(index, participiantKind)
    }, [participiantKind, onRemoveData])

    const { t } = useTranslation();

    return (
        <Grid container spacing={1}>
            <Grid item sm={6} xs={6} />
            {
                participiants?.filter((p) =>p.icopart_active === true).map((participiant, index) => {
                    return (
                        <Grid item xs={12}>
                            <ParticipiantForm
                                full_name={participiant?.full_name}
                                note={participiant?.icopart_note}
                                icopart_date_realization={participiant?.icopart_date_realization}
                                icopart_date_accept={participiant?.icopart_date_accept}
                                icopart_date_send={participiant?.icopart_date_send}
                                icopart_date_reject = {participiant?.icopart_date_reject}
                                index={index}
                                onEditData={onChangeDataByGroup}
                                onRemoveData={onRemoveUserFromGroup}
                                readOnly={readOnly && participiant.participiant_id !== undefined}
                                showDates={showDates}
                                kind={participiantKind}
                            />
                        </Grid>
                    )
                })
            }
            <Grid item xs={12}>
                {(!readOnly || (participiantKind === "informed" ? allowAddNewInforemdParticipiant: !readOnly))&&
                    <Button
                        fullWidth
                        variant="text"
                        size="small"
                        onClick={onAddUserByGroup}
                    >
                        {t(`other.ico_participiant_box.add_${participiantKind}`)}
                    </Button>
                }
            </Grid>
        </Grid>
    );
});

IcoParticipiantBox.propTypes = {
    participiants: PropTypes.array,
    onEditParticipiant: PropTypes.func,
    onAddBoard: PropTypes.func,
    onRemoveBoard: PropTypes.func,
    readOnly: PropTypes.bool,
    allowAddNewInforemdParticipiant : PropTypes.bool
}


IcoParticipiantBox.defaultProps = {
    participiants: [],
    readOnly: true,
    allowAddNewInforemdParticipiant : false
}
export default IcoParticipiantBox