import { useState } from "react";
import useDialog from "./useDialog";

const useDialogWithIcoData = (initialOpen = false, initialId = null, modeView = "preview",  consolidatedIcoList = []) => {
  const [openDialog, handleOpenDialog, handleCloseDialog] =
    useDialog(initialOpen);
  const [id, setId] = useState(initialId);
  const [parentId, setParentId] = useState(undefined);
  const [selectedMode, setSelectedMode] = useState(modeView);
  const [consolidatedIcos, setConsolidatedIcos] = useState(consolidatedIcoList)


  const handleOpenDialogWithIcoData = (id, modeView, parentId = undefined,consolidatedIcoList = []) => {
    setId(id);
    setSelectedMode(modeView)
    setParentId(parentId)
    setConsolidatedIcos(consolidatedIcoList)
    handleOpenDialog();
  };

  const handleCloseDialogWithIcoData = () => {
    setId(null);
    setParentId(null)
    handleCloseDialog();
    setConsolidatedIcos([])
    setSelectedMode("preview")
  };

  return [openDialog, id, parentId, selectedMode, consolidatedIcos, handleOpenDialogWithIcoData, handleCloseDialogWithIcoData];
};

export default useDialogWithIcoData;
