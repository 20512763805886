import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid, Button } from "@mui/material";
import BasicDialog from "../../base/BasicDialog";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import useIcoService from "../../../services/icoService";
import { useState, useEffect, useCallback, useRef } from "react";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import TableService from "../../../services/tableService";
import BaseTable from "../../base/BaseTable/BaseTable";
import { ICO_PARTICIPIANTS_TABLE_FIELDS_CONFIG } from "./TablesConfig";
import useDialog from "../../../hooks/useDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import AddParticipiantDialog from "../AddParticipiantDialog/AddParticipiantDialog";
import { getErrorMsg } from "../../../helpers/methods";
import { ICO_ACCEPT, ICO_REJECT } from "../../../helpers/constants";
import ConfirmationDialog from "../ConfirmationDialog/confirmationDialog";

const IcoAcceptDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();
  const [participiantsLocal, setParticipiantsLocal] = useState([]);
  const [icoNr, setIcoNr] = useState("");
  const [participiantUserIds, setParticipiantUserIds] = useState([])
  const participiantRejectId = useRef();

  const { getParticipiants, updateIco } = useIcoService();
  const updateIcoFn = useAsyncFn(updateIco)

  const [
    openNewParticipiantDialog,
    onOpenNewParticipiantDialog,
    onCloseNewParticipiantDialog,
  ] = useDialog();

  const [
    openConfirmationDialog,
    onOpenConfirmationDialog,
    onCloseConfirmationDialog,
  ] = useDialog();

  const participiantsData = useAsync(
    () => getParticipiants(props.icoId),
    [props.icoId]
  );

  const readOnly = participiantsData.loading

  useEffect(() => {
    if (participiantsData.loading) {
      return;
    }
    let participiantsLocal = participiantsData.value.participiants
    setParticipiantsLocal(participiantsLocal);
    setParticipiantUserIds(participiantsLocal.map((p) => p.icopart_person))
    setIcoNr(participiantsData.value.ico_nr)
  }, [participiantsData.loading]);

  const onClickAcccept = useCallback((e, id) => {
    onUpdateIco({
      ico: { ico: props.icoId },
      ico_participiants: [{ id: id }],
      action: ICO_ACCEPT
    },
      t("snackbar_alert.ico_agreement_accepted")
    )
  }, [])

  const closeWindow = useCallback(() => {
    props.onRefetchData()
    props.onClose()
  }, [])

  const onClickReject = useCallback((e, id) => {
    participiantRejectId.current = id
    onOpenConfirmationDialog()
  }, [props.icoId])

  const onAddNewParticipiant = useCallback((data) => {
    onUpdateIco({
      ico: { ico: props.icoId },
      ico_participiants: [data]
    },
      t("snackbar_alert.added_participiant")
    )
    onCloseNewParticipiantDialog();
  }, [props.icoId])

  const onUpdateIco = (data, snackbarAlertLabel, action = undefined) => {
    updateIcoFn
      .execute(props.icoId, data)
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          snackbarAlertLabel
        );
        if (action === ICO_REJECT) {
          closeWindow()
        } else {
          participiantsData.refetch()
          participiantRejectId.current = undefined
        }
      })
      .catch((error) => {

        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(error.data),
          t("snackbar_alert.occurred_error_during_updating_request")
        );
      });
  };

  const handleCloseConfirmationDialog = (confirmRejectIco) => {
    onCloseConfirmationDialog();
    if (confirmRejectIco) {
      onUpdateIco({
        ico: { ico: props.icoId },
        ico_participiants: [{ id: participiantRejectId.current }],
        action: ICO_REJECT
      },
        t("snackbar_alert.ico_agreement_rejected"),
        ICO_REJECT
      )
    }
    participiantRejectId.current = undefined
  };



  return (
    <BasicDialog
      open={props.open}
      onClose={closeWindow}
      titleAlign="center"
      contentAlign="center"
      title={icoNr}
      maxWidth="lg"
      showDialogActions
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        <LoaderWrapper showLoader={participiantsData.loading || updateIcoFn.loading}>
          <Grid item xs={12}>
            <BaseTable
              headers={TableService.getHeaderLables(ICO_PARTICIPIANTS_TABLE_FIELDS_CONFIG
              )}
              rows={TableService.getPreparedDataForBaseTable(ICO_PARTICIPIANTS_TABLE_FIELDS_CONFIG,
                participiantsLocal,
                [],
                [],
                onClickAcccept,
                onClickReject,
                readOnly
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={onOpenNewParticipiantDialog}
            >
              {t("dialog.ico_accept_dialog.add_participiant")}
            </Button>
          </Grid>
        </LoaderWrapper>
      </Grid>
      {openNewParticipiantDialog &&
        <AddParticipiantDialog
          open={openNewParticipiantDialog}
          onClose={onCloseNewParticipiantDialog}
          searchParams={{ 'is_active': true, 'is_external': false, 'exclude_ids': participiantUserIds }}
          icoId={props.icoId}
          onAddParticipiant={onAddNewParticipiant}
        />
      }
      {openConfirmationDialog && (
        <ConfirmationDialog
          open={openConfirmationDialog}
          onNo={() => handleCloseConfirmationDialog(false)}
          onYes={() => handleCloseConfirmationDialog(true)}
          title={t("dialog.ico_accept_dialog.alert")}
          content={t("dialog.ico_accept_dialog.are_you_sure_to_reject_ico")}
        />
      )}
    </BasicDialog>
  );
};

IcoAcceptDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

IcoAcceptDialog.defaultProps = {
  open: false,
};

export default IcoAcceptDialog;
