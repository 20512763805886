import i18n from "../../../i18n";
import DateService from "../../../services/dateService";
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export const ICO_PARTICIPIANTS_TABLE_FIELDS_CONFIG = [

  {
    name: "full_name",
    getValueCallback: (rowData) => rowData.full_name,
    label: i18n.t("table.participiants_accept_table.person"),
  },  {
    name: "participiant_kind",
    getValueCallback: (rowData) =>   i18n.t(`table.participiants_accept_table.${rowData.participiant_kind}`) , 
    label: i18n.t("table.participiants_accept_table.role"),
  },
  {
    name: "icopart_date_send",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.icopart_date_send),
    label: i18n.t("table.participiants_accept_table.date_send"),
  },
  {
    name: "icopart_note",
    getValueCallback: (rowData) => rowData.icopart_note,
    label: i18n.t("table.participiants_accept_table.accept_scope"),
  },
  {
    name: "icopart_date_accept",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.icopart_date_accept),
    label: i18n.t("table.participiants_accept_table.date_accept"),
  },
  {
    name: "icopart_date_reject",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.icopart_date_reject),
    label: i18n.t("table.participiants_accept_table.date_reject"),
  },
  {
    name: "accept",
    getValueCallback: (rowData, onClickAccept, onClickReject, readOnly) => {
      const content = (
        <DoneOutlineIcon
          onClick={(e) => onClickAccept(e, rowData.participiant_id)}
          fontSize="large"
          color="success"
        />
      );
      return rowData.allow_accept_or_reject && !readOnly ? content : "";
    },
    label: i18n.t("table.participiants_accept_table.accepting"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "reject",
    getValueCallback: (rowData, onClickAccept, onClickReject, readOnly) => {
      const content = (
        <HighlightOffIcon
          onClick={(e) => onClickReject(e, rowData.participiant_id)}
          fontSize="large"
          color="error"
        />
      );
      return rowData.allow_accept_or_reject && !readOnly ? content : "";
    },
    label: i18n.t("table.participiants_accept_table.reject"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  }
];
